import React from 'react'
import Layout from '../components/layout'
import LogoDesigner from '../components/categories/logo-designer'
import Seo from '../components/seo'

const LogoDesignerPage = ({ location }) => {
  return (
    <Layout location={location}>
      <LogoDesigner />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top Logo Designer Freelancers | Codestaff'
  const description =
    'Hire the best Logo Designer freelancers at Codestaff. Get the top 1% of Logo Designer professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default LogoDesignerPage
