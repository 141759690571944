import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

const faqMeta1 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "How Long Does A Logo Design Take?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Subject to the workload, a logo design can take up to 2 weeks. Time scales are also dependent on the client’s response time and the number of additional changes to designs."
        }
    }
}

const faqMeta2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "How Do I Order A Logo Design Online?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "To place an order, you only need to fill a short and simple form with your requirement details. After that, place your order and you will receive your logo design concepts via the email you supplied. That is how simple your logo design is with Codestaff."
        }
    }
}

const productMeta = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": "Logo Design",
    "image": "https://codestaff.io/logo-designer.png",
    "description": "Freelance Logo Designers at Code Staff make beautiful logos for branding, digital, print and web. Hire a Logo designer on the codestaff directory and have them join your team.",
    "brand": "Code Staff",
    "offers": {
        "@type": "Offer",
        "url": "https://codestaff.io/logo-designer",
        "priceCurrency": "USD",
        "price": "99",
        "priceValidUntil": "2020-05-27",
        "availability": "https://schema.org/OnlineOnly",
        "itemCondition": "https://schema.org/NewCondition"
    },
    "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "5",
        "bestRating": "5",
        "worstRating": "1",
        "ratingCount": "1",
        "reviewCount": "1"
    },
    "review": {
        "@type": "Review",
        "name": "David Alleva",
        "reviewBody": "",
        "reviewRating": {
            "@type": "Rating",
            "ratingValue": "5",
            "bestRating": "5",
            "worstRating": "1"
        },
        "datePublished": "2020-05-06",
        "author": { "@type": "Person", "name": "David Alleva" },
        "publisher": { "@type": "Organization", "name": "Freelancer Website" }
    }
}

const LogoDesigner = () => (
    <main>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
            <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
            <script type="application/ld+json">{JSON.stringify(productMeta)}</script>
        </Helmet>
        <section className="cx_catagories">
            <div className="container-fluid cx_top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-sm-10">
                            <h2>
                                Hire top freelance<br /><span> Logo Designers.</span>
                            </h2>
                            <p>
                                Codestaff is a marketplace for top Logo Designers. Top
                                companies and start-ups choose Codestaff professional Logo
                                Designers for their mission critical software projects.
                            </p>
                            <Link to="/hire/" className="skills-banner-btn">
                                Hire a top Logo Designer now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <CxSearch title='HIRE FREELANCE LOGO DESIGNERS' banner='/logo-designer.png' bannerAlt='logo designer banner' />
        </section>
        <HireAndApply />
        <TopTrendingSkills />
    </main>
)

export default LogoDesigner